import { Select } from "@blueprintjs/select";
import { Button, MenuItem } from "@blueprintjs/core";
import React, { useCallback } from "react";
import "styled-components/macro";
import { StyledFormGroup } from "src/components/forms-stuff/styled-form-group";

export function ItemSelect({
  value,
  onValueChange,
  helperText,
  name,
  label,
  leftIcon,
  isVisible = true,
  allItems,
  disabled = false,
  ...props
}) {
  const onChange = useCallback(
    (ev) => {
      onValueChange(ev);
    },
    [onValueChange]
  );

  return !isVisible ? null : (
    <StyledFormGroup
      helperText={helperText}  // TODO: fix opacity issue when selecting the dropdown
      label={label}
      labelFor={name}
      {...props}
    >
      <Select
        filterable={false}
        itemRenderer={(v, { handleClick, modifiers }) => {
          return (
            <MenuItem
              key={v}
              active={false}
              label={""}
              onClick={handleClick}
              text={v}
            />
          );
        }}
        items={allItems}
        onItemSelect={onChange}
      >
        <Button
          large
          icon={leftIcon}
          text={value || `Select...`}
          rightIcon="caret-down"
          alignText="left"
          style={{ minWidth: "260px" }}
        />
      </Select>
    </StyledFormGroup>
  );
}
