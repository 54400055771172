import styled from "styled-components/macro";

import ClinicsTable from "src/entities/clinics/clinics-table";
import ClinicsPageCallout from "src/entities/clinics/clinics-page-callout";

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: space-between;
  align-items: flex-start;
`;

const ClinicsPage = () => (
  <PageContainer>
    <ClinicsPageCallout />
    <ClinicsTable />
  </PageContainer>
);

export default ClinicsPage;
