import _ from "lodash";

export function parseBoolean(value: string | boolean): boolean | null {
  if (value === true || value === false) {
    return value;
  }
  value = value && _.trim(value);
  if (value === "true") {
    return true;
  } else if (value === "false") {
    return false;
  } else {
    return null;
  }
}
