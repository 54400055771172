import { useCanDoLevelWrites } from "../helpers/authorization";
import { AgColDef } from "../../components/AgTable/types";
import { useStoreActions } from "../../hooks/ep";

export function useSendNotificationFlagColDef(): AgColDef {
  const canWriteSendMessage = useCanDoLevelWrites(2);
  const handleMarkNotificationForSending = useStoreActions(
    (a) => a.upcoming_notifications.handleMarkNotificationForSending
  );
  return {
    field: "should_send",
    headerName: "Send Notification?",
    headerTooltip:
      "If checked, the notification will be sent in the next batch. If unchecked, the notification will not be sent in the next batch.",
    type: "booleanColumn",
    width: 150,
    pinned: "right",
    ...(!canWriteSendMessage
      ? {}
      : {
          cellRenderer: "checkBoxCellRenderer",
          cellRendererParams: {
            crp: {
              onChange: handleMarkNotificationForSending,
              buildPayload: (row, field, new_should_send) => ({
                row,
                new_should_send,
              }),
            },
          },
        }),
  };
}
