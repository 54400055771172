import { Redirect, Route } from "react-router";
import { useIsAuthorized } from "./helpers/useAuthorization";
import useRedirect from "./hooks/useRedirect";
import { UserRole } from "./store/model-me";
import { Icon as BP3Icon } from "@blueprintjs/core";
import AppWrapper from "./components/wrapper/AppWrapper";
import { SigninPage } from "./pages/signin-page";
import { CmaxUsersPage } from "./cmax-users/pages/cmax-users-page";
import { UpcomingNotificationsPage } from "./pages/upcoming_notifications/upcoming-notifications-page";
import { SentNotificationsPage } from "./pages/sent-notifications-page";
import ClinicsPage from "src/pages/clinics-page";

export enum Routes {
  root = "/",
  dev = "/dev",
  signin = "/signin",
  cmax_users = "/cmax-users",
  //
  upcoming_notifications = "/upcoming-notifications",
  sent_notifications = "/sent-notifications",
  clinics = "/clinics",
  //
  DEFAULT = upcoming_notifications,
}

export const DEFAULT_ROUTES_BY_ROLE = {
  [UserRole.SAXECAP_DEV]: Routes.DEFAULT,
  [UserRole.ADMIN_READ_WRITE]: Routes.DEFAULT,
  [UserRole.ADMIN_READ_ONLY]: Routes.DEFAULT,
  [UserRole.OBSERVER_READ_WRITE]: Routes.DEFAULT,
  [UserRole.OBSERVER_READ_ONLY]: Routes.DEFAULT,
  [UserRole.DEFAULT]: Routes.DEFAULT,
};

export const SAXECAP_ROUTES_PATT = /^\/.*$/;
export const ADMIN_ROUTES_PATT =
  /^\/(signin|cmax-users|upcoming-notifications|sent-notifications|clinics)$/;
export const OBSERVER_ROUTES_PATT = ADMIN_ROUTES_PATT;
export const DEFAULT_ROUTES_PATT =
  /^\/(signin|upcoming-notifications|sent-notifications)$/;

export const ROLE_ALLOWED_ROUTES_PATTS = {
  [UserRole.SAXECAP_DEV]: SAXECAP_ROUTES_PATT,
  [UserRole.ADMIN_READ_WRITE]: ADMIN_ROUTES_PATT,
  [UserRole.ADMIN_READ_ONLY]: ADMIN_ROUTES_PATT,
  [UserRole.OBSERVER_READ_WRITE]: OBSERVER_ROUTES_PATT,
  [UserRole.OBSERVER_READ_ONLY]: OBSERVER_ROUTES_PATT,
  [UserRole.DEFAULT]: DEFAULT_ROUTES_PATT,
};

export const USER_EXTRA_ALLOWED_ROUTES_PATTS = {};

export const leftNavMenuItems = [
  {
    urlPath: Routes.upcoming_notifications,
    title: "Upcoming Notifications",
    icon: <BP3Icon icon={"notifications"} />,
  },
  {
    urlPath: Routes.sent_notifications,
    title: "Sent Notifications",
    icon: <BP3Icon icon={"history"} />,
  },
  {
    urlPath: Routes.cmax_users,
    title: "Customax Users",
    icon: <BP3Icon icon={"people"} />,
  },
  {
    urlPath: Routes.clinics,
    title: "Clinics",
    icon: <BP3Icon icon={"office"} />,
  },
];

export const BREADCRUMBS = [
  {
    href: Routes.cmax_users,
    icon: "people",
    text: "Customax Users",
  },
  {
    href: Routes.upcoming_notifications,
    icon: "notifications",
    text: "Upcoming Notifications",
  },
  {
    href: Routes.sent_notifications,
    icon: "history",
    text: "Sent Notifications",
  },
  {
    href: Routes.clinics,
    icon: "office",
    text: "Clinics",
  },
];

function MyRoute({ route, Component, protect }) {
  let WrappedComponent;
  if (protect) {
    WrappedComponent = (props) => {
      const auth = useIsAuthorized(route);
      const doneLoading = !auth.loading;
      const isDefinitelyNotAuthorized = doneLoading && !auth.isAuthorized;
      const isDefinitelyAuthorized = doneLoading && auth.isAuthorized;
      useRedirect(() => isDefinitelyNotAuthorized, Routes.signin);
      return isDefinitelyAuthorized ? (
        <AppWrapper hideLeftNav={false}>
          <Component {...props} />
        </AppWrapper>
      ) : null;
    };
  } else {
    WrappedComponent = (props) => (
      <AppWrapper hideLeftNav={false}>
        <Component {...props} />
      </AppWrapper>
    );
  }
  return <Route exact path={route} component={WrappedComponent} key={route} />;
}

export const AllRoutes = [
  MyRoute({
    route: Routes.signin,
    Component: SigninPage,
    protect: false,
  }),
  MyRoute({
    route: Routes.upcoming_notifications,
    Component: UpcomingNotificationsPage,
    protect: true,
  }),
  MyRoute({
    route: Routes.sent_notifications,
    Component: SentNotificationsPage,
    protect: true,
  }),
  MyRoute({
    route: Routes.cmax_users,
    Component: CmaxUsersPage,
    protect: true,
  }),
  MyRoute({
    route: Routes.clinics,
    Component: ClinicsPage,
    protect: true,
  }),
  <Route render={() => <Redirect to={Routes.signin} />} key="default_route" />,
];
