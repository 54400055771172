import React from "react";
import AgTable from "../../components/AgTable";
import { AgColDefs } from "../../components/AgTable/types";
import { useConfigSelection } from "../../components/selection/config";
import { useSendNotificationFlagColDef } from "./use-send-notification-flag-col-def";
import { useStoreState } from "../../hooks/ep";
import { UpcomingNotificationRow } from "../../store/model-upcoming-notifications";
import { timestampVF } from "../../components/AgTable/value-formatting";

import { formatPhoneNumber } from "../../common/format-phone-number";

export function UpcomingNotificationsTable(props) {
  const [selectedConfig] = useConfigSelection();

  let rowData = useStoreState(
    (s) => s.upcoming_notifications.initialData
  ) as UpcomingNotificationRow[];

  if (selectedConfig.code !== "") {
    rowData = rowData.filter(
      (task) => task.config_code === selectedConfig.code
    );
  }

  const colDefs = useColDefs();

  return (
    <AgTable
      rowData={rowData}
      columnDefs={colDefs}
      tableName="upcoming-notifications"
      ribbonTitle={"Upcoming Notifications"}
      {...props}
    />
  );
}

function useColDefs(): AgColDefs {
  const upcomingNotifications_SendNotificationFlag_ColDef =
    useSendNotificationFlagColDef();

  return [
    {
      headerName: "Patient Number",
      field: "patient_person_number",
      type: "textColumn",
      width: 100,
      pinned: "left",
    },
    {
      headerName: "Patient Name",
      field: "patient_name",
      type: "textColumn",
    },
    {
      headerName: "Configuration Name",
      field: "config_name",
      type: "categoryColumn",
      headerTooltip:
        "Name of the configuration associated with this notification",
    },
    {
      headerName: "Triggered At",
      field: "notification_for_config_triggered_at",
      type: "timestampColumn",
      valueFormatter: (params) => {
        const tzNaiveTimestampString = timestampVF(params);

        /*
        NOTE: The `notification_for_config_triggered_at` is assumed to come from a 'Create Stamp Task Activity' column
          in Cranial's data warehouse (Practice Analytics). For now, we assume that
          all such timestamps follow the time zone of the city "Tempe, Arizona"
          (where Cranial is headquartered), which does not follow daylight saving time,
          and is therefore in MST (GMT-7) for the entire year, not MST for part of the year
          and MDT for the other part of the year. We will need to verify this assumption
          after March (when daylight saving time starts), possibly by adding a
          quick text task note manually on a call with Laura and Batool, recording
          the UTC time when we manually added that note, and then looking up that
          note in the data warehouse the next day and seeing what value is in the
          'Create Stamp Task Activity' column for that note -- this will tell
          us what time zone that column is using during daylight saving time.
          Note that "America/Phoenix" is the time zone for all cities in Arizona, including Tempe.
         */
        return `${tzNaiveTimestampString} (MST)`;
      },
      headerTooltip:
        "The creation timestamp of the task note (quick text note) that triggered this notification",
    },
    {
      headerName: "Notification Time",
      field: "notification_time",
      type: "categoryColumn",
      headerTooltip:
        "The time the notification will be sent (9-9:30am in the time zone local to the region)",
      width: 140,
    },
    {
      headerName: "Region",
      field: "source_task_user_assigned",
      type: "categoryColumn",
      headerTooltip: "A task's region is the user assigned to the task",
      width: 140,
    },
    {
      headerName: "Priority",
      field: "source_task_priority",
      type: "categoryColumn",
      width: 100,
    },
    {
      headerName: "Task Type",
      field: "source_task_type",
      type: "categoryColumn",
      width: 120,
    },
    {
      headerName: "Task Description",
      field: "source_task_description",
      type: "textColumn",
    },
    {
      headerName: "Contact Email",
      field: "contact_email_address",
      type: "textColumn",
      headerTooltip:
        'The patient\'s contact email address ("-" if unknown/missing)',
      valueFormatter: (params) => params.value || "-",
    },
    {
      headerName: "Contact Phone",
      field: "contact_mobile_phone_number",
      type: "textColumn",
      headerTooltip:
        'The patient\'s contact mobile phone number ("-" if unknown/missing)',
      valueFormatter: (params) => formatPhoneNumber(params.value),
    },
    {
      headerName: "PCP Name",
      field: "pcp_name",
      type: "textColumn",
      headerTooltip: "The name of the patient's Primary Care Physician",
    },
    {
      headerName: "PCP Address",
      field: "pcp_address",
      type: "textColumn",
      headerTooltip: "The address of the patient's Primary Care Physician",
      width: 365,
    },
    upcomingNotifications_SendNotificationFlag_ColDef,
  ];
}
