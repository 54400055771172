import { MenuItem } from "@blueprintjs/core";

import DeclareClinicFormContent from "src/components/clinics/declare-clinic-form-content";
import { parseClinicToPayload } from "src/entities/clinics/parse-object-values";
import { useStoreActions, useStoreState } from "src/hooks/ep";
import { FormType, useOpenDrawerForm } from "src/store/model-drawer-form";
import { Clinic } from "../../entities/clinics/user-actions";

const EditClinicMenuItem = ({ data }) => {
  const openDrawer = useOpenDrawerForm();
  const handleSubmit = useStoreActions(
    (actions) => actions.clinics.handleDeclareClinic
  );
  const clinicsByUUID = useStoreState((state) => state.clinics.rowDataByUUID);

  const currentClinicData = clinicsByUUID[data.uuid] as Clinic;

  const onClick = () => {
    const title = "Please edit the clinic's details";
    const initialData = {
      fieldName2Value: parseClinicToPayload(currentClinicData),
    };
    const ContentComponent = DeclareClinicFormContent;
    openDrawer({
      initialData,
      title,
      ContentComponent,
      handleSubmit,
      formType: FormType.DeclareClinic,
    });
  };

  return <MenuItem icon="edit" text="Edit" onClick={onClick} />;
};

export default EditClinicMenuItem;
