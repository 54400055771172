import _ from "lodash";
// eslint-disable-next-line no-restricted-imports
import { css } from "styled-components";
import "styled-components/macro";
import { useGridBorderRadius } from "./use-grid-border-radius";
import { Colors } from "@blueprintjs/core";

export function useExtraGridCss(columnDefs) {
  const brad = useGridBorderRadius();

  if (!_.size(columnDefs)) {
    return undefined;
  }

  return css`
    overflow: hidden;
    border-radius: 0 0 ${brad} ${brad};
    width: 100%;

    & .ag-header-cell:not(hover) {
      & .ag-header-icon.ag-header-cell-menu-button {
        opacity: 0.2 !important;
      }
    }

    & .ag-header-cell:hover {
      background-color: #283540;

      & .ag-header-icon.ag-header-cell-menu-button {
        opacity: 1 !important;
      }
    }

    /*& .react-rendered-cell span.ag-cell-value {
      width: 100%;
    }*/

    & .ag-row-odd {
      background-color: #30404d;

      &.ag-row-hover {
        background-color: ${Colors.DARK_GRAY5};
      }
    }

    & .ag-row-even {
      background-color: #293742;

      &.ag-row-hover {
        background-color: ${Colors.DARK_GRAY5};
      }
    }

    & .ag-floating-top-viewport,
    & .ag-floating-bottom-viewport {
      background-color: #24303b;
    }

    & .ag-row-pinned {
      background-color: #24303b;

      &[role="row"] {
        height: 36px !important;
      }

      &.ag-row-even {
        background-color: #212c36;
      }

      &.ag-row-hover {
        background-color: ${Colors.DARK_GRAY5};
      }
    }

    & .ag-header-cell.ag-column-hover {
      background-color: ${Colors.DARK_GRAY5};
    }

    & .ag-numeric-cell {
      text-align: right;

      & .ag-cell-wrapper[role="presentation"] {
        justify-content: flex-end;
      }
    }

    & .ag-text-cell {
      text-align: left;
      & .ag-cell-wrapper {
        justify-content: flex-start;
      }
      & .bp3-button .bp3-button-text {
        text-align: left;
      }
    }

    & span.ag-cell-value[role="gridcell"],
    & span.ag-cell-value[role="presentation"] {
      width: 100%;
    }

    & .ag-filter-apply-panel > button {
      background-color: rgb(37, 43, 46);
    }

    & span.ag-cell-value[role="gridcell"] {
      width: 100%;
    }

    // prettier-ignore
    & .ag-root {
      & .ag-body-viewport,
      & .ag-floating-top,
      & .ag-floating-bottom {
        & .ag-row[role="row"] .ag-cell[role="gridcell"][col-id][comp-id] {
          display: flex;
          flex-direction: column;
          align-items: stretch;
          justify-content: space-around;
        }
      }
    }
  `;
}

// const BIG_HEADER_CELLS = css`
//   & .ag-header-cell-label .ag-header-cell-text {
//     white-space: normal !important;
//   }
//
//   & .ag-header-cell::after {
//     top: unset !important;
//     height: 60% !important;
//     margin-top: unset !important;
//   }
// `;

export const CSS_TO_ALWAYS_SHOW_SCROLLBARS = `
  .ag-grid-body-viewport {
    overflow: scroll !important;
  }
  
  && ::-webkit-scrollbar {
    -webkit-appearance: none !important;
    width: 8px !important;
    height: 8px !important;
  }

  && ::-webkit-scrollbar-track {
    //-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1) !important;
  }

  && ::-webkit-scrollbar-thumb {
    -webkit-appearance: none !important;
    border-radius: 4px !important;
    background-color: rgba(255, 255, 255, 0.7) !important;
    box-shadow: 0 0 -3px 0 rgba(255, 255, 255, 0.5) !important;
  }
`;
