import { CmaxUserModel, getCmaxUserModel } from "./model-cmax-user";
import {
  getUpcomingNotificationsModel,
  UpcomingNotificationsModel,
} from "./model-upcoming-notifications";
import {
  ConfigurationsModel,
  getConfigurationsModel,
} from "./model-configurations";
import { ClinicsModel, getClinicsModel } from "src/store/model-clinics";

export interface TableModels {
  cmax_user: CmaxUserModel;
  upcoming_notifications: UpcomingNotificationsModel;
  clinics: ClinicsModel;
  configurations: ConfigurationsModel;
}

export type TableModelSliceName =
  | "cmax_user"
  | "upcoming_notifications"
  | "clinics"
  | "configurations";

export const tableModels: TableModels = {
  cmax_user: getCmaxUserModel(),
  upcoming_notifications: getUpcomingNotificationsModel(),
  clinics: getClinicsModel(),
  configurations: getConfigurationsModel(),
};
