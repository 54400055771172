import {
  Action,
  action,
  computed,
  Computed,
  State,
  Thunk,
  thunkOn,
  ThunkOn,
} from "easy-peasy";
import { Injections } from "./store-injections";
import { StoreModel } from "./model";
import { TableModelSliceName } from "./table-models";
import _ from "lodash";
import { assert } from "../helpers/assertions";
import memo from "memoizerific";

export type TableRowId = string | number;
export type TableRow = { id?: TableRowId; [column: string]: any };
export type TableData = TableRow[];
export type QueryParams = object | null;
export interface GetRowId {
  (row: TableRow): TableRowId;
}
export interface TableModel {
  NAME: TableModelSliceName;
  INITIAL_DATA_ENDPOINT: string;
  fetchQueryParams: QueryParams;
  GET_ROW_ID: GetRowId;
  relationships: Relationship[];
  initialData: TableData;
  initialDataReceived: boolean;
  initialDataLoading: boolean;
  //
  resetData: Action<TableModel>;
  onLogout: ThunkOn<TableModel, Injections, StoreModel>;
  receiveInitialData: Action<TableModel, TableData>;
  upsertRow: Action<TableModel, TableRow>;
  deleteRow: Action<TableModel, TableRowId>;
  rowsById: Computed<
    TableModel,
    // Ignore the "String" and "Number suffixes. Had to set it up
    // like this to get TypeScript to stop complaining about union types..
    { [rowIdString: string]: TableRow; [rowIdNumber: number]: TableRow },
    StoreModel
  >;
  getRow: Computed<
    TableModel,
    (rowId: TableRowId) => undefined | TableRow,
    StoreModel
  >;
  rowIds: Computed<TableModel, Set<TableRowId>, StoreModel>;
  rowExists: Computed<TableModel, { (row: TableRow): boolean }, StoreModel>;
  //
  relationshipsByName: Computed<
    TableModel,
    { [relationshipName: string]: Relationship },
    StoreModel
  >;
  getRelated: Computed<
    TableModel,
    (
      relationshipName: string,
      rowId: TableRowId
    ) => null | TableRow | TableRow[],
    StoreModel
  >;
  relatedIdsMap: Computed<
    TableModel,
    {
      [rowIdString: string]: {
        [relationshipName: string]: null | TableRowId | TableRowId[];
      };
      [rowIdNumber: number]: {
        [relationshipName: string]: null | TableRowId | TableRowId[];
      };
    },
    StoreModel
  >;
  relatedRowsMap: Computed<
    TableModel,
    {
      [rowIdString: string]: {
        [relationshipName: string]: null | TableRow | TableRow[];
      };
      [rowIdNumber: number]: {
        [relationshipName: string]: null | TableRow | TableRow[];
      };
    },
    StoreModel
  >;
  //
  setFetchQueryParams: Action<TableModel, QueryParams>;
  markInitialDataReceived: Action<TableModel>;
  markInitialDataNotReceived: Action<TableModel>;
  markInitialDataLoading: Action<TableModel>;
  markInitialDataNotLoading: Action<TableModel>;
  //
  maybeHandleFetchInitialData: Thunk<TableModel, void, Injections, StoreModel>;
  handleFetchInitialData: Thunk<TableModel, void, Injections, StoreModel>;
}

export type Arity = "OneToMany" | "OneToOne" | "ManyToOne" | "ManyToMany";
export interface Relationship {
  name: string;
  arity: Arity;
  field: string;
  foreignName: TableModelSliceName;
  foreignField: string | string[];
  foreignFilter?: { (row: TableRow): boolean };
}

export function getField(row: TableRow, field: string | string[]): any {
  if (_.isString(field)) {
    return row[field];
  } else {
    return field.reduce(
      (previousValue, currentValue) => previousValue?.[currentValue],
      row
    );
  }
}

function getRelatedRows(
  row: TableRow,
  relationship: Relationship,
  storeState: State<StoreModel>
): null | TableRow | TableRow[] {
  const relatedModelSlice = storeState[relationship.foreignName];
  const rowValueForField = getField(row, relationship.field);
  const relatedRows: TableRow[] = relatedModelSlice.initialData
    .filter(
      (foreignRow) =>
        !relationship.foreignFilter || relationship.foreignFilter(foreignRow)
    )
    .filter(
      (foreignRow) =>
        getField(foreignRow, relationship.foreignField) === rowValueForField
    );
  if (["OneToOne", "ManyToOne"].includes(relationship.arity)) {
    assert(relatedRows.length <= 1);
    if (relatedRows.length === 1) {
      return relatedRows[0];
    } else {
      return null;
    }
  } else {
    return relatedRows;
  }
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getRelatedIds(
  row: TableRow,
  relationship: Relationship,
  storeState: State<StoreModel>
): null | TableRowId | TableRowId[] {
  const relatedRows = getRelatedRows(row, relationship, storeState);
  if (_.isNull(relatedRows)) {
    return null;
  } else if (_.isArray(relatedRows)) {
    return relatedRows.map((row) => row.id);
  } else {
    return relatedRows.id;
  }
}

export function tableModelFactory(
  sliceName: TableModelSliceName,
  initialDataEndpoint: string,
  getRowId: GetRowId,
  relationships?: Relationship[],
  transformInitialData?: any,
  initialDataFormat?: string,
  initialFetchQueryParams?: QueryParams
): TableModel {
  const memoArray = memo(10)((...values) => values);

  return {
    NAME: sliceName,
    INITIAL_DATA_ENDPOINT: initialDataEndpoint,
    fetchQueryParams: initialFetchQueryParams ?? null,
    INITIAL_DATA_FORMAT: initialDataFormat ?? "json",
    GET_ROW_ID: getRowId,
    relationships: relationships || [],
    initialData: [],
    initialDataReceived: false,
    initialDataLoading: false,
    resetData: action((state) => {
      state.initialData = [];
      state.initialDataReceived = false;
      state.initialDataLoading = false;
    }),
    onLogout: thunkOn(
      (__, { me }) => me.resetData,
      (actions) => {
        actions.resetData();
      }
    ),
    receiveInitialData: action((state, payload) => {
      let initialData = payload;
      if (transformInitialData) {
        initialData = transformInitialData(initialData);
      }
      state.initialData = initialData.map((row) => ({
        ...row,
        id: getRowId(row),
      }));
    }),
    upsertRow: action((state, payload) => {
      payload = { ...payload, id: getRowId(payload) };
      const currIndex = state.initialData.findIndex(
        (row) => row.id === payload.id
      );
      if (currIndex === -1) {
        state.initialData.push(payload);
      } else {
        state.initialData[currIndex] = payload;
      }
    }),
    deleteRow: action((state, rowId) => {
      state.initialData = state.initialData.filter((row) => row.id !== rowId);
    }),
    relationshipsByName: computed([(s) => s.relationships], (rels) =>
      Object.fromEntries(rels.map((rel) => [rel.name, rel]))
    ),
    rowsById: computed([(s) => s.initialData], (initialData) =>
      Object.fromEntries(initialData.map((row) => [row.id, row]))
    ),
    getRow: computed([(s) => s.initialData], (initialData) => (rowId) => {
      return initialData.find((row) => row.id === rowId);
    }),
    getRelated: computed(
      [
        (s) => s.relationshipsByName,
        (s) => s.rowsById,
        (state, storeState) => storeState,
      ],
      (relationshipsByName, rowsById, storeState) =>
        (relationshipName, rowId) => {
          const relationship = relationshipsByName[relationshipName];
          const row = rowsById[rowId];
          if (_.isUndefined(relationship) || _.isUndefined(row)) {
            throw Error(
              JSON.stringify({ relationshipName, rowId, relationship, row })
            );
          }

          return getRelatedRows(row, relationship, storeState);
        }
    ),
    relatedIdsMap: computed(
      [
        (s) => s.relationships,
        (s) => s.initialData,
        (state, storeState) =>
          memoArray(
            ..._.uniqBy(relationships || [], "foreignName")
              .map((rela) => rela.foreignName)
              .map((sliceName) => storeState[sliceName].initialData)
          ),
        // (state, storeState) =>
        //   Object.fromEntries(
        //     _.uniqBy(relationships || [], "foreignName")
        //       .map((rela) => rela.foreignName)
        //       .map((sliceName) => [
        //         sliceName,
        //         storeState[sliceName].initialData,
        //       ])
        //   ),
        // (state, storeState) => storeState,
      ],
      (relationships: Relationship[], initialData: TableRow[], storeStates) => {
        // console.log("storeStates", storeStates);
        const foreignName_to_rels = _.groupBy<Relationship>(
          relationships,
          (rel) => rel.foreignName
        );

        const field_to_fieldValue_to_rows: Map<
          string,
          Map<number | string, TableRow[]>
        > = new Map();

        // const field2mapkey = (fld) =>
        //   (_.isString(fld) ? [fld] : fld).join("..");

        _.uniq(relationships.map((rel) => rel.field)).forEach((field) => {
          field_to_fieldValue_to_rows.set(
            // field2mapkey(field),
            field,
            groupBy(initialData, (localRow) => getField(localRow, field))
          );
        });

        const ret = {};

        Object.entries(foreignName_to_rels).forEach(
          (
            [foreignName, relsWithSameForeignName]: [string, Relationship[]],
            idx
          ) => {
            const foreignInitialData: TableRow[] = storeStates[idx];

            const foreignField_to_rels = _.groupBy<Relationship>(
              relsWithSameForeignName,
              (rel) => rel.foreignField
            );

            Object.entries(foreignField_to_rels).forEach(
              ([foreignField, relsWithSameForeignNameAndField]: [
                string,
                Relationship[]
              ]) => {
                const foreignFieldValue_to_foreignRows: Map<
                  number | string,
                  TableRow[]
                > = groupBy(foreignInitialData, (foreignRow) =>
                  getField(foreignRow, foreignField)
                );

                foreignFieldValue_to_foreignRows.forEach(
                  (foreignRows, foreignFieldValue) => {
                    relsWithSameForeignNameAndField.forEach((rel) => {
                      const localRowsWithMatchingFieldValues =
                        field_to_fieldValue_to_rows
                          .get(rel.field)
                          // .get(field2mapkey(rel.field))
                          ?.get(foreignFieldValue) ?? [];

                      const filteredForeignRows = !rel.foreignFilter
                        ? foreignRows
                        : foreignRows.filter(rel.foreignFilter);

                      const filteredForeignRowIds = filteredForeignRows.map(
                        (r) => r.id
                      );

                      const relatedIdsValue = arity_to_finalizer[rel.arity](
                        filteredForeignRowIds
                      );

                      localRowsWithMatchingFieldValues.forEach((localRow) => {
                        const rowId = localRow.id;
                        ret[rowId] = {
                          ...(ret[rowId] || {}),
                          [rel.name]: relatedIdsValue,
                        };
                      });
                    });
                  }
                );
              }
            );
          }
        );
        return ret;
      }
    ),
    relatedRowsMap: computed(
      [
        (s) => s.relationships,
        (s) => s.relatedIdsMap,
        (state, storeState) =>
          memoArray(
            ..._.uniqBy(relationships || [], "foreignName")
              .map((rela) => rela.foreignName)
              .map((sliceName) => storeState[sliceName].rowsById)
          ),
        // (state, storeState) =>
        //   Object.fromEntries(
        //     _.uniqBy(relationships || [], "foreignName")
        //       .map((rela) => rela.foreignName)
        //       .map((sliceName) => [sliceName, storeState[sliceName].rowsById])
        //   ),
        // (state, storeState) => storeState,
      ],
      (relationships, relatedIdsMap, storeStates) => {
        const relName_to_rel = Object.fromEntries(
          relationships.map((rel) => [rel.name, rel])
        );

        const entries: [
          TableRowId,
          { [key: string]: null | TableRowId | TableRowId[] }
        ][] = Object.entries(relatedIdsMap);

        return Object.fromEntries(
          entries.map(([rowId, relName_to_relIds]) => {
            const relName_to_relRows = Object.fromEntries(
              Object.entries(relName_to_relIds).map(
                ([relName, relIdsValue]: [
                  string,
                  null | TableRowId | TableRowId[]
                ]) => {
                  const rel = relName_to_rel[relName];
                  const foreignRowsById =
                    storeStates[
                      relationships.findIndex(
                        (rell) => rell.foreignName === rel.foreignName
                      )
                    ];

                  const relIdsArray = _.isArray(relIdsValue)
                    ? relIdsValue
                    : [relIdsValue];

                  const relRowsValue: null | TableRow | TableRow[] =
                    arity_to_finalizer[rel.arity](
                      relIdsArray.map((id) => foreignRowsById[id])
                    );

                  return [relName, relRowsValue];
                }
              )
            );
            return [rowId, relName_to_relRows];
          })
        );
      }
    ),
    //
    setFetchQueryParams: undefined,
    markInitialDataReceived: undefined,
    markInitialDataNotReceived: undefined,
    markInitialDataLoading: undefined,
    markInitialDataNotLoading: undefined,
    //
    maybeHandleFetchInitialData: undefined,
    handleFetchInitialData: undefined,
    rowIds: computed(
      [(s) => s.initialData, (s) => s.GET_ROW_ID],
      (initialData: TableRow[], GET_ROW_ID: GetRowId) =>
        new Set(initialData.map((row) => GET_ROW_ID(row)))
    ),
    rowExists: computed(
      [(s) => s.rowIds, (s) => s.GET_ROW_ID],
      (rowIds: Set<TableRowId>, GET_ROW_ID: GetRowId) => (row) =>
        rowIds.has(GET_ROW_ID(row))
    ),
  };
}

function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

const arity_to_finalizer = {
  OneToMany: (v) => v,
  ManyToMany: (v) => v,
  OneToOne: (v) => v[0] ?? null,
  ManyToOne: (v) => v[0] ?? null,
};
