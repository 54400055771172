import { Spinner } from "@blueprintjs/core";

import VALID_REGIONS from "src/common/valid-regions";
import {
  FormItemConfig,
  FormItem,
  FormItemType,
} from "src/components/drawer-forms/inputs/form-item";
import { useEnsureData } from "src/helpers/use-ensure-data";
import { useFieldName2Value, useSetField } from "src/store/model-drawer-form";

export const ClinicFormInputs = ({
  fieldName2Value,
  setField,
  activeField,
  setActiveField,
}) => {
  const formInputConfigs: FormItemConfig[] = [
    {
      name: "name",
      label: "Clinic name",
      helperText: "The name of the clinic",
      leftIcon: "office",
      formItemType: FormItemType.TEXT_INPUT,
      allowSpaces: true,
    },
    {
      name: "code",
      label: "Clinic code",
      helperText:
        "The 4-letter code of the clinic, used as the Type for that clinic's Tasks in Intergy",
      leftIcon: "code",
      formItemType: FormItemType.TEXT_INPUT,
    },
    {
      name: "state",
      label: "State",
      helperText:
        "The 2-letter state abbreviation for the state where the clinic is located",
      leftIcon: "flag",
      formItemType: FormItemType.TEXT_INPUT,
    },
    {
      name: "region",
      label: "Region",
      helperText: "The region to which the clinic is associated",
      leftIcon: "map",
      formItemType: FormItemType.ITEM_SELECT,
      allSelectableItems: VALID_REGIONS,
    },
  ];

  return (
    <>
      {formInputConfigs.map(
        ({
          name,
          label,
          helperText,
          leftIcon,
          formItemType,
          allSelectableItems,
          allowSpaces,
        }) => (
          <FormItem
            key={name}
            helperText={helperText}
            label={label}
            name={name}
            leftIcon={leftIcon}
            formItemType={formItemType}
            allSelectableItems={allSelectableItems}
            value={fieldName2Value[name]}
            onValueChange={(value) => setField({ name, value, allowSpaces })}
            activeField={activeField}
            setActiveField={setActiveField}
          />
        )
      )}
    </>
  );
};

const DeclareClinicFormContent = ({ activeField, setActiveField }) => {
  const loading = useEnsureData("clinics");
  const fieldName2Value = useFieldName2Value();
  const setField = useSetField();
  return loading ? (
    <Spinner />
  ) : (
    <ClinicFormInputs
      fieldName2Value={fieldName2Value}
      setField={setField}
      activeField={activeField}
      setActiveField={setActiveField}
    />
  );
};

export default DeclareClinicFormContent;
