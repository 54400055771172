import { submitUserActionPostRequest } from "src/user-actions/user_actions";

export interface Clinic {
  code: string;
  name: string;
  region: string;
  state: string;
  active: boolean;
  uuid: string;
}

export const declareClinic = async (clinic: Clinic) =>
  await submitUserActionPostRequest("declare_clinic", clinic);
