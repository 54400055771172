import { actions } from "./commonActions";
import { thunks } from "./commonThunks";
import { FirebaseModel, firebaseModel } from "./model-firebase";
import { meModel, MeModel } from "./model-me";
import miscModel, { MiscModel } from "./model-misc";
import { TableModels, tableModels, TableModelSliceName } from "./table-models";
import { DrawerFormModel, drawerFormModel } from "./model-drawer-form";
import { CmaxUserModel } from "./model-cmax-user";
import {
  EntitySelectionModel,
  entitySelectionModel,
} from "./model-entity-selection";
import { UpcomingNotificationsModel } from "./model-upcoming-notifications";
import { ClinicsModel } from "src/store/model-clinics";
import {
  ConfigSelectionModel,
  modelConfigSelection,
} from "./model-config-selection";

export interface EmailPassword {
  email: string;
  password: string;
}

// The interface representing our entire store model
export interface StoreModel extends TableModels {
  me: MeModel;
  firebase: FirebaseModel;
  misc: MiscModel;
  entitySelection: EntitySelectionModel;
  drawerForm: DrawerFormModel;
  //
  cmax_user: CmaxUserModel;
  upcoming_notifications: UpcomingNotificationsModel;
  clinics: ClinicsModel;
  configSelection: ConfigSelectionModel;
}

const model: StoreModel = {
  me: meModel,
  firebase: firebaseModel,
  misc: miscModel,
  entitySelection: entitySelectionModel,
  drawerForm: drawerFormModel,
  //
  cmax_user: tableModels.cmax_user,
  upcoming_notifications: tableModels.upcoming_notifications,
  clinics: tableModels.clinics,
  configSelection: modelConfigSelection,
  //
  ...tableModels,
};

export type BackendConnectedModelSliceName = TableModelSliceName | "me";

export type ModelSliceName =
  | BackendConnectedModelSliceName
  | "firebase"
  | "misc"
  | "entitySelection"
  | "drawerForm";

function isResourceModel(modelSlice) {
  return !!modelSlice.INITIAL_DATA_ENDPOINT;
}

Object.entries(model).forEach(([modelSliceName, modelSlice]) => {
  if (!isResourceModel(modelSlice)) {
    return;
  }

  modelSlice.initialDataReceived = false;
  modelSlice.initialDataLoading = false;

  const myActions = actions();
  [
    "setFetchQueryParams",
    "markInitialDataReceived",
    "markInitialDataNotReceived",
    "markInitialDataLoading",
    "markInitialDataNotLoading",
    "receiveInitialData",
    "clear",
  ].forEach((actionName) => {
    if (!modelSlice[actionName]) {
      modelSlice[actionName] = myActions[actionName];
    }
  });

  const myThunks = thunks(modelSliceName);
  ["handleFetchInitialData", "maybeHandleFetchInitialData"].forEach(
    (thunkName) => {
      if (!modelSlice[thunkName]) {
        modelSlice[thunkName] = myThunks[thunkName];
      }
    }
  );
});

export { model };
