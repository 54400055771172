import React, { useMemo } from "react";
import "styled-components/macro"; // DO NOT REMOVE. Necessary for using the css={`...`} prop
import CategoryColumnFilter from "./categoryColumnFilter";
import {
  DivToPutIconInMiddleOfCell,
  StarCellRenderer,
} from "./star-cell-renderer";
import { useGridContext } from "./my-ag-grid-react-context";

import { GridOptions } from "@ag-grid-community/core/dist/es6/entities/gridOptions";
import { RelationshipAggregationCellRenderer } from "../../entities/helpers/relation-agg-cr";
import { StatsTooltip } from "./stats-tooltip";
import TagsColumnFilter from "./tagsColumnFilter";
import { TagsCellRenderer } from "./tags-cell-renderer";
import { BasicBp3Tooltip } from "./basic-bp3-tooltip";
import { LinkedTextCellRenderer } from "./cell-renderers/linked-text/cell-renderer";
import { UrlCellRenderer } from "./cell-renderers/url/cell-renderer";
import { MultiLinkedTextCellRenderer } from "./cell-renderers/multi-linked-text/cell-renderer";
import {
  booleanVF,
  dateVF,
  floatVF,
  integerIdentifierVF,
  integerVF,
  moneyVF,
  percentageVF,
  timestampVF,
} from "./value-formatting";
import _ from "lodash";
import { ValueGetterParams } from "@ag-grid-community/core/dist/es6/entities/colDef";
import { TakeActionButtonCellRenderer } from "./cell-renderers/take-action-button/cell-renderer";
import { CheckBoxCellRenderer } from "./cell-renderers/check-box-cell-renderer";
import { SentNotificationViewerButtonCellRenderer } from "../table-actions/sent-notification-viewer-cell-renderer";

const USE_BIG_HEADERS = false;

export function timestampComparator(filterLocalValue, cellValue) {
  // Assume dates are stored as iso
  const cellDate = cellValue && new Date(cellValue);
  const filterLocalDate = filterLocalValue && new Date(filterLocalValue);

  if (!cellDate && !filterLocalDate) {
    return 0;
  } else if (!filterLocalDate) {
    return -1;
  } else if (!cellValue) {
    return 1;
  } else if (filterLocalDate < cellDate) {
    return -1;
  } else if (filterLocalDate > cellDate) {
    return 1;
  } else {
    return 0;
  }
}

export const nfAmericaMoney = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const AG_NUMERIC_CELL_CLASS = "ag-numeric-cell";

const NUMERIC_COLUMN_PROPERTIES = {
  filter: "agNumberColumnFilter",
  headerClass: "ag-numeric-header",
  cellClass: AG_NUMERIC_CELL_CLASS,
};

const TEXT_COLUMN_PROPERTIES = {
  cellClass: "ag-text-cell",
};

// @ts-ignore
const window_Cypress = !!window.Cypress;

// noinspection JSUnresolvedFunction
const DEFAULT_GRID_OPTIONS: GridOptions = {
  columnTypes: {
    dateColumn: {
      filter: "agDateColumnFilter",
      // filterParams: {
      //   comparator: timestampComparator,
      // },
      // comparator: timestampComparator,
      // @ts-ignore
      valueGetter: (params: ValueGetterParams): Date | null => {
        // console.log("dateColumn:valueGetter:params", params);
        const value = params.data[params.colDef.field];
        let ret;
        if (_.isDate(value)) {
          ret = value;
        } else if (_.isString(value) && value) {
          ret = new Date(value);
        } else if (_.isNumber(value) && value) {
          ret = new Date(value);
        } else {
          ret = null;
        }

        if (ret !== null) {
          // @ts-ignore
          const tz = params.colDef.__timezone;
          if ((tz ?? "UTC") !== "UTC") {
            ret = ret.toDateString();
          } else {
            const year = ret.getUTCFullYear().toString();
            const month = _.padStart(
              (ret.getUTCMonth() + 1).toString(),
              2,
              "0"
            );
            const day = _.padStart(ret.getUTCDate().toString(), 2, "0");
            ret = `${year}-${month}-${day}`;
          }
        }
        // console.log("dateColumn valueGetter value:", value);
        // console.log("dateColumn valueGetter ret:", ret);
        return ret;
      },
      valueFormatter: dateVF,
      headerTooltip: "_",
      tooltipComponent: "statsTooltip",
    },
    timestampColumn: {
      filter: "agDateColumnFilter",
      // filterParams: {
      //   comparator: timestampComparator,
      // },
      // comparator: timestampComparator,
      valueGetter: (params: ValueGetterParams): Date | null => {
        // console.log("timestampColumn:valueGetter:params", params);
        const value = params.data[params.colDef.field];
        if (_.isDate(value)) {
          return value;
        } else if (_.isString(value) && value) {
          return new Date(value);
        } else if (_.isNumber(value) && value) {
          return new Date(value);
        } else {
          return null;
        }
      },
      valueFormatter: timestampVF,
      headerTooltip: "_",
      tooltipComponent: "statsTooltip",
    },
    booleanColumn: {
      filter: "categoryFilterer",
      valueFormatter: booleanVF,
    },
    percentageColumn: {
      valueFormatter: percentageVF,
      ...NUMERIC_COLUMN_PROPERTIES,
      headerTooltip: "_",
      tooltipComponent: "statsTooltip",
    },
    integerColumn: {
      valueFormatter: integerVF,
      ...NUMERIC_COLUMN_PROPERTIES,
      headerTooltip: "_",
      tooltipComponent: "statsTooltip",
    },
    integerIdentifierColumn: {
      valueFormatter: integerIdentifierVF,
      ...NUMERIC_COLUMN_PROPERTIES,
    },
    floatColumn: {
      valueFormatter: floatVF,
      ...NUMERIC_COLUMN_PROPERTIES,
      headerTooltip: "_",
      tooltipComponent: "statsTooltip",
    },
    moneyColumn: {
      valueFormatter: moneyVF,
      ...NUMERIC_COLUMN_PROPERTIES,
      headerTooltip: "_",
      tooltipComponent: "statsTooltip",
    },
    urlColumn: {
      filter: "agTextColumnFilter",
      cellRenderer: "urlRenderer",
      ...TEXT_COLUMN_PROPERTIES,
    },
    linkedTextColumn: {
      filter: "agTextColumnFilter",
      cellRenderer: "linkedTextRenderer",
      ...TEXT_COLUMN_PROPERTIES,
    },
    multiLinkedTextColumn: {
      filter: "agTextColumnFilter",
      cellRenderer: "multiLinkedTextRenderer",
      ...TEXT_COLUMN_PROPERTIES,
    },
    textColumn: {
      filter: "agTextColumnFilter",
      ...TEXT_COLUMN_PROPERTIES,
    },
    categoryColumn: {
      filter: "categoryFilterer",
      ...TEXT_COLUMN_PROPERTIES,
    },
    tagsColumn: {
      filter: "tagsFilterer",
      cellRenderer: "tagsCellRenderer",
      cellClass: "react-rendered-cell",
    },
  },
  frameworkComponents: {
    statsTooltip: StatsTooltip,
    basicBp3Tooltip: BasicBp3Tooltip,
    urlRenderer: UrlCellRenderer,
    linkedTextRenderer: LinkedTextCellRenderer,
    multiLinkedTextRenderer: MultiLinkedTextCellRenderer,
    categoryFilterer: CategoryColumnFilter,
    starCellRenderer: StarCellRenderer,
    relationshipAggregationCellRenderer: RelationshipAggregationCellRenderer,
    tagsFilterer: TagsColumnFilter,
    tagsCellRenderer: TagsCellRenderer,
    takeActionButtonCellRenderer: TakeActionButtonCellRenderer,
    notificationViewerButtonCellRenderer:
      SentNotificationViewerButtonCellRenderer,
    checkBoxCellRenderer: CheckBoxCellRenderer,
  },
  defaultColDef: {
    resizable: true,
    sortable: true,
    sortingOrder: ["desc", "asc", null],
    filter: true,
    suppressAutoSizeColumns: !window_Cypress,
    filterParams: {
      applyButton: true,
      clearButton: true,
      debounceMs: 200,
    },
    maxWidth: USE_BIG_HEADERS ? 250 : undefined,
    // @ts-ignore
    __pinnedRowCellRenderer: ({ valueFormatted }) => (
      <DivToPutIconInMiddleOfCell>{valueFormatted}</DivToPutIconInMiddleOfCell>
    ),
  },
  immutableData: true,
  getRowNodeId: (data) => data.id,
  // headerHeight: 48,
  // rowHeight: 48,
  getRowHeight: (params) => params.data?.__rowHeight ?? 48,
  enableCellTextSelection: true,
  // enableBrowserTooltips: false,

  // all rows assigned CSS class 'my-green-class'
  rowClass: "ag-table--row--odd",

  // all odd rows assigned 'my-shaded-effect'
  getRowClass: (params) =>
    params.node.rowIndex % 2 === 0 ? "ag-table--row--even" : null,

  skipHeaderOnAutoSize: false,

  /* Label columns */
  headerHeight: 64,
  animateRows: false,
  suppressColumnVirtualisation: false,
  // suppressFieldDotNotation: true
  // enableBrowserTooltips: true,
  // tooltipShowDelay: 1500,
  // tooltipMouseTrack: true,
  singleClickEdit: true,
  cacheQuickFilter: true,
};

function useGridOptions({
  scrollbarWidth,
  ...onEvents
}: GridOptions): GridOptions {
  const eventListeners = Object.values(onEvents);
  const context = useGridContext();
  const ret = useMemo(
    () => ({
      scrollbarWidth,
      ...onEvents,
      ...DEFAULT_GRID_OPTIONS,
      context,
    }),
    // eslint-disable-next-line
    [context, eventListeners, scrollbarWidth]
  );
  return ret as GridOptions;
}

export { useGridOptions };
