import React from "react";
import { Callout, Dialog, H4 } from "@blueprintjs/core";
import { useStoreActions, useStoreState } from "../hooks/ep";
import { Letter } from "react-letter";
import styled from "styled-components/macro";
import { Intent } from "@blueprintjs/core/lib/esm/common/intent";
import {
  SentEmailNotificationContent,
  SentNotificationContent,
  SentSmsNotificationContent,
} from "../store/model-misc";
import _ from "lodash";

export const SentNotificationContentDialog = () => {
  const sentNotificationContent = useStoreState(
    (s) => s.misc.sentNotificationContent
  );
  const handleCloseSentNotificationViewerOverlays = useStoreActions(
    (a) => a.misc.handleCloseSentNotificationViewerOverlays
  );
  const notificationType = sentNotificationContent?.type;
  return (
    <StyledDialog
      canEscapeKeyClose
      canOutsideClickClose
      key={"sent-notification-content-dialog"}
      //@ts-ignore
      onClose={handleCloseSentNotificationViewerOverlays}
      className={"bp3-dark"}
      icon={notificationType === "sms" ? "mobile-phone" : "envelope"}
      title={`${notificationType === "sms" ? "SMS" : "Email"} Content`}
      contentType={notificationType}
      isOpen={!!sentNotificationContent}
      usePortal={true}
    >
      <StyledDialogContent className="bp3-dialog-body">
        <NotificationContent notificationContent={sentNotificationContent} />
      </StyledDialogContent>
    </StyledDialog>
  );
};

const NotificationContent = ({
  notificationContent,
}: {
  notificationContent: SentNotificationContent;
}) => {
  const notificationType = notificationContent?.type;
  if (notificationType === "email") {
    const { subject, body } =
      notificationContent as SentEmailNotificationContent;
    return <EmailNotificationContent subject={subject} body={body} />;
  } else if (notificationType === "sms") {
    const {
      original_message: originalMessage,
      actual_messages: actualMessages,
    } = notificationContent as SentSmsNotificationContent;
    console.log("originalMessage:", originalMessage);
    console.log("actualMessages:", actualMessages);
    return (
      <SmsNotificationContent
        originalMessage={originalMessage}
        actualMessages={actualMessages}
      />
    );
  } else {
    return null;
  }
};

const EmailNotificationContent = ({ subject, body }) => {
  const normalizedBody = normalizeMessageColor(body);
  return (
    <>
      <H4>Email Subject</H4>
      <Callout
        key={"email-subject-callout"}
        className={"email-subject-callout"}
        intent={Intent.NONE}
      >
        <span>{subject}</span>
      </Callout>
      <Separator />
      <H4>Email Body</H4>
      <Callout
        key={"email-body-callout"}
        className={"email-body-callout"}
        intent={Intent.NONE}
      >
        <Letter html={normalizedBody} />
      </Callout>
    </>
  );
};

const SmsNotificationContent = ({ originalMessage, actualMessages }) => {
  return (
    <>
      <H4 key={"sms-original-message-header"}>
        SMS Content Submitted to Teligent API
      </H4>
      <Callout
        key={"sms-original-message-callout"}
        className={"sms-original-message-callout"}
        intent={Intent.NONE}
      >
        <Letter html={smsContentToHtml(originalMessage)} />
      </Callout>
      <Separator />
      <H4 key={"sms-actual-messages-header"}>
        Actual SMS Messages Sent by Teligent API
      </H4>
      <div key={"sms-actual-messages"} className={"sms-actual-messages"}>
        {actualMessages.map((message, index) => (
          <Callout
            key={`sms-actual-message-callout-${index}`}
            intent={Intent.NONE}
            className={"sms-actual-message-callout"}
          >
            <Letter html={smsContentToHtml(message)} />
          </Callout>
        ))}
      </div>
    </>
  );
};

function normalizeMessageColor(message: string) {
  if (!message) {
    return "";
  }
  return message.replaceAll(/color:\s?#000000/g, "");
}

function smsContentToHtml(smsContent: string): string {
  // HTML escape the text
  const escapedSmsContent = _.escape(smsContent);
  return `<span>${escapedSmsContent}</span>`;
}

const StyledDialog = styled(Dialog)`
  min-width: ${(props) => (props.contentType === "email" ? "1024px" : "480px")};
`;

const StyledDialogContent = styled.div`
  & .sms-actual-messages {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

const Separator = styled.div`
  height: 48px;
`;
