import React from "react";
import { IconName } from "@blueprintjs/core";
import { ItemSelect } from "src/components/drawer-forms/inputs/item-select";
import { TextInput } from "src/components/drawer-forms/inputs/text-input";

export enum FormItemType {
  TEXT_INPUT = "text_input",
  ITEM_SELECT = "item_select",
}

export interface FormItemConfig {
  name: string;
  label: string;
  helperText: string;
  leftIcon: IconName;
  formItemType: FormItemType;
  allSelectableItems?: string[];
  allowSpaces?: boolean;
}

export function FormItem({
  name,
  label,
  helperText,
  leftIcon,
  formItemType,
  allSelectableItems,
  value,
  onValueChange,
  activeField,
  setActiveField,
  ...props
}: {
  name: string;
  label: string;
  helperText: string;
  leftIcon: IconName;
  formItemType: FormItemType;
  allSelectableItems?: string[];
  value: any;
  onValueChange: any;
  activeField: string;
  setActiveField: (field: string) => void;
}): JSX.Element {
  switch (formItemType) {
    case FormItemType.ITEM_SELECT:
      return (
        <ItemSelect
          key={name}
          label={label}
          name={name}
          helperText={helperText}
          onValueChange={onValueChange}
          value={value}
          allItems={allSelectableItems}
          leftIcon={leftIcon}
          {...props}
        />
      );
    case FormItemType.TEXT_INPUT:
      return (
        <TextInput
          key={name}
          helperText={helperText}
          label={label}
          name={name}
          value={value}
          leftIcon={leftIcon}
          onValueChange={onValueChange}
          activeField={activeField}
          setActiveField={setActiveField}
          {...props}
        />
      );
    default:
      throw new Error(`Unexpected FormItemType: ${formItemType}`);
  }
}
