import React from "react";
import { DEFAULT_SHOULD_FETCH, useEndpoint } from "../helpers/use-endpoint";
import { Spinner } from "@blueprintjs/core";
import AgTable from "../components/AgTable";
import { AgColDefs } from "../components/AgTable/types";
import { SentNotificationContentDialog } from "../components/sent-notification-content-dialog";
import { formatPhoneNumber } from "../common/format-phone-number";

export function SentNotificationsPage() {
  const { data, loading } = useEndpoint(
    "sent_notifications",
    DEFAULT_SHOULD_FETCH,
    { responseFormat: "json" }
  );
  return loading ? (
    <Spinner />
  ) : (
    <>
      <SentNotificationContentDialog />
      <SentNotificationsTable rowData={data} />
    </>
  );
}

function SentNotificationsTable({ rowData }) {
  const colDefs = useColDefs();
  return (
    <AgTable
      rowData={rowData}
      columnDefs={colDefs}
      tableName="sent-notifications"
      ribbonTitle={"Sent Notifications"}
      fullScreenEnabled={true}
    />
  );
}

function useColDefs(): AgColDefs {
  return [
    {
      headerName: "Customax Sent Notification ID",
      field: "id",
      type: "integerIdentifierColumn",
      hide: true,
    },
    {
      headerName: "Notification Sent At",
      field: "notification_sent_at",
      type: "timestampColumn",
      __timezone: "America/Phoenix",
      headerTooltip:
        "Time at which the notification was sent (Arizona timezone).",
      width: 263,
    },
    {
      headerName: "TO",
      field: "to_contact",
      type: "textColumn",
      headerTooltip: "Which email/phone the notification was sent to",
      tooltipField: "to_contact",
      cellClass: ["ellipses-tooltip-cell"],
      width: 300,
      valueFormatter: (params) => {
        // Look in data. If notification_message_type is "sms",
        // then format the value using phone number value formatter.
        // Otherwise, return the value as-is.

        // noinspection JSUnresolvedReference
        if (params.data.notification_message_type === "sms") {
          return formatPhoneNumber(params.value);
        }
      },
    },
    {
      headerName: "Patient Number",
      field: "patient_person_number",
      type: "textColumn",
      width: 100,
    },
    {
      headerName: "Patient Name",
      field: "patient_name",
      type: "textColumn",
    },
    {
      headerName: "Configuration Name",
      field: "config_name",
      type: "categoryColumn",
      headerTooltip:
        "Name of the configuration associated with this notification",
    },
    {
      headerName: "Email or SMS",
      field: "notification_message_type",
      type: "categoryColumn",
      headerTooltip:
        "Whether this row represents a sent email notification or a sent SMS notification",
    },
    // TODO: Triggered At (notification_for_config_triggered_at) column
    // {
    //   headerName: "Notification Time",
    //   field: "notification_time",
    //   type: "textColumn",
    //   headerTooltip:
    //     "The time the notification will be sent (9am in the time zone local to the region)",
    //   width: 140,
    // },
    {
      headerName: "Region",
      field: "source_task_user_assigned",
      type: "categoryColumn",
      headerTooltip: "A task's region is the user assigned to the task",
      width: 140,
    },
    {
      headerName: "Priority",
      field: "source_task_priority",
      type: "categoryColumn",
      width: 100,
    },
    {
      headerName: "Task Type",
      field: "source_task_type",
      type: "categoryColumn",
      width: 120,
    },
    {
      headerName: "Task Description",
      field: "source_task_description",
      type: "textColumn",
    },
    {
      headerName: "Contact Email",
      field: "patient_contact_email_address",
      type: "textColumn",
      headerTooltip:
        'The patient\'s contact email address ("-" if unknown/missing)',
      valueFormatter: (params) => params.value || "-",
    },
    {
      headerName: "Contact Phone Number",
      field: "patient_contact_mobile_phone_number",
      type: "textColumn",
      headerTooltip:
        'The patient\'s contact mobile phone number ("-" if unknown/missing)',
      valueFormatter: (params) => formatPhoneNumber(params.value),
    },
    {
      headerName: "",
      field: "id",
      cellClass: "react-rendered-cell",
      cellRenderer: "notificationViewerButtonCellRenderer",
      width: 52,
      pinned: "right",
    },
  ];
}
