import { parseBoolean } from "src/common/parse-boolean";
import { parseFieldsToString } from "src/common/parse-object-values";
import { Clinic } from "src/entities/clinics/user-actions";
import { FieldName2Value } from "src/store/model-drawer-form";

export const parsePayloadToClinic = (payload: Clinic | FieldName2Value) => {
  return {
    name: payload.name,
    code: payload.code,
    region: payload.region,
    state: payload.state,
    active: parseBoolean(payload.active),
    uuid: payload.uuid,
  } as Clinic;
};

export const parseClinicToPayload = (clinic: Clinic) => {
  const parsedClinic = parseFieldsToString(clinic);
  return {
    name: parsedClinic.name,
    code: parsedClinic.code,
    region: parsedClinic.region,
    state: parsedClinic.state,
    active: parsedClinic.active,
    uuid: parsedClinic.uuid,
  } as FieldName2Value;
};
