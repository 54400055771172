import React from "react";
import { Callout } from "@blueprintjs/core";

export function UpcomingNotificationCallout(): JSX.Element {
  const content = useCalloutContent();
  return (
    <Callout
      intent={null}
      title={null}
      css={`
        font-size: 17px;
      `}
    >
      {content}
    </Callout>
  );
}

function useCalloutContent(): JSX.Element {
  const content = `Batches of notifications for each region are sent out at 9:00 AM or 9:30 AM local time on weekdays (Mon-Fri). Each row in the table below is a notification that will be sent out in the next batch only if its "Send Notification?" checkbox is checked. Notifications that are not sent out in the next batch will never be sent, as notifications expire after 24 weekday hours have passed. The information on this page is updated at least once every hour.`;

  return <span>{content}</span>;
}
