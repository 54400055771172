const VALID_REGIONS = [
  // TODO: retrieve these from the backend
  //       I tried to, but I'm too dumb to understand the models/store/whatever
  //       that gets data from the backend, and this needs to be deployed today.
  "Midwest Region",
  "West Region",
  "East Region",
  "NEast Region",
  "SEast Region",
  "NorCal Region",
  "SoCal Region",
  "South Region",
];
export default VALID_REGIONS;
