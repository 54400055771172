import React from "react";
import { useEnsureData } from "../../helpers/use-ensure-data";
import { Spinner } from "@blueprintjs/core";
import { ConfigSelector } from "../../components/selection/config";
import { Col, Row } from "antd";
import styled from "styled-components/macro";
import { UpcomingNotificationsTable } from "../../entities/upcoming_notifications/upcoming-notifications-table";
import { UpcomingNotificationCallout } from "../../components/upcoming_notifications/upcoming-notifications-callout";
import _ from "lodash";

export function UpcomingNotificationsPage() {
  const loading = _.max([
    useEnsureData("upcoming_notifications"),
    useEnsureData("configurations"),
  ]);
  // @ts-ignore
  return loading ? <Spinner /> : <UpcomingNotificationsPageContainer />;
}

function UpcomingNotificationsPageContainer() {
  return (
    <PageContainer>
      <div
        css={`
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
        `}
      >
        <Row
          css={`
            width: 100%;
            flex-wrap: nowrap;
            align-items: center;
            margin-bottom: 48px;
            max-height: 200px;
          `}
        >
          <Col flex={"250px"}>
            <ConfigSelector />
          </Col>
          <Col flex={"auto"} style={{ paddingLeft: "100px" }}>
            <UpcomingNotificationCallout />
          </Col>
        </Row>
        <UpcomingNotificationsTable
          fullScreenEnabled={false}
          height={"calc(100vh - 64px - 40px - 96px - 48px - 40px - 48px)"} // 96px is the height of the callout when it takes up 3 lines of text. Probably a better way to do this..
        />
      </div>
    </PageContainer>
  );
}

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 100%;
`;
