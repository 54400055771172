import { Spinner } from "@blueprintjs/core";

import AgTable from "src/components/AgTable";
import { AgColDefs } from "src/components/AgTable/types";
import EditClinicMenuItem from "src/components/clinics/edit-clinic-menu-item";
import { useCanEditClinic } from "src/entities/clinics/authorization";
import useCustomRibbonLeftElements from "src/entities/clinics/use-custom-ribbon-left-elements";
import { useEnsureData } from "src/helpers/use-ensure-data";
import { useStoreActions, useStoreState } from "src/hooks/ep";

const useColumnDefs = () => {
  const canEditClinic = useCanEditClinic();
  const handleUpdateClinicActive = useStoreActions(
    (actions) => actions.clinics.handleUpdateClinicActive
  );
  return [
    {
      headerName: "Clinic",
      field: "name",
      type: "textColumn",
      width: 200,
      headerTooltip:
        "The clinic's name. For documentation/filtering purposes only (does not affect Customax's functionality).",
    },
    {
      headerName: "Code",
      field: "code",
      type: "textColumn",
      headerTooltip:
        "The clinic's unique code. Affects Customax's functionality (see explanation above).",
    },
    {
      headerName: "State",
      field: "state",
      type: "textColumn",
      headerTooltip:
        "The clinic's state. For documentation/filtering purposes only (does not affect Customax's functionality).",
    },
    {
      headerName: "Region",
      field: "region",
      type: "textColumn",
      headerTooltip:
        "The clinic's region. For documentation/filtering purposes only (does not affect Customax's functionality).",
    },
    {
      headerName: "Active",
      field: "active",
      headerTooltip:
        "If checked, the tasks corresponding to this clinic will trigger notifications when indicated by a task note. " +
        "If unchecked, the tasks corresponding to this clinic will NEVER trigger notifications.",
      type: "booleanColumn",
      ...(canEditClinic
        ? {
            cellRenderer: "checkBoxCellRenderer",
            cellRendererParams: {
              crp: {
                onChange: handleUpdateClinicActive,
                buildPayload: (data, field, value) => [data.uuid, value],
              },
            },
          }
        : {}),
    },
    {
      headerName: "",
      field: "action",
      cellRenderer: "takeActionButtonCellRenderer",
      cellRendererParams: {
        crp: {
          popoverMenuItems: [EditClinicMenuItem],
          getDisabled: () => !canEditClinic,
        },
      },
      pinned: "right",
      __authorized: canEditClinic,
    },
  ] as AgColDefs;
};

export const ClinicsTableInner = ({ fullScreenEnabled = true, ...props }) => {
  let rowData = useStoreState((state) => state.clinics.initialData);
  const columnDefs = useColumnDefs();
  const customRibbonLeftElements = useCustomRibbonLeftElements();
  return (
    <AgTable
      rowData={rowData}
      tableName="Clinics"
      ribbonTitle="Clinics"
      columnDefs={columnDefs}
      customRibbonLeftElements={customRibbonLeftElements}
      {...props}
    />
  );
};

const ClinicsTable = (props) => {
  const loading = useEnsureData("clinics");
  return loading ? <Spinner /> : <ClinicsTableInner {...props} />;
};

export default ClinicsTable;
