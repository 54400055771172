import { Action, action, ActionOn, actionOn } from "easy-peasy";
import { StoreModel } from "./model";
import { Config } from "../components/selection/config";

export const DEFAULT_CONFIG = {
  name: "",
  code: "",
};

export interface ConfigSelectionModel {
  NAME: string;
  selectedConfig: Config;
  selectConfig: Action<ConfigSelectionModel, Config>;
  onLogout: ActionOn<ConfigSelectionModel, StoreModel>;
}

export const modelConfigSelection: ConfigSelectionModel = {
  NAME: "configSelection",
  selectedConfig: DEFAULT_CONFIG,
  selectConfig: action((state, newConfig) => {
    state.selectedConfig = newConfig;
  }),
  onLogout: actionOn(
    (__, { me }) => me.resetData,
    (state, target) => {
      state.selectedConfig = DEFAULT_CONFIG;
    }
  ),
};
