import {
  TableModel,
  tableModelFactory,
  TableRow,
  TableRowId,
} from "./table-model-factory";
import { Actions, Thunk, thunk } from "easy-peasy";
import {
  ActionPayload_MarkNotificationForSending,
  submitUserAction__mark_notification_for_sending,
} from "../user-actions/submit_user_action__mark_notification_for_sending";
import { toaster } from "../toaster";
import { Injections } from "./store-injections";
import { StoreModel } from "./model";

export interface UpcomingNotificationsModel extends TableModel {
  handleMarkNotificationForSending: Thunk<
    UpcomingNotificationsModel,
    HandleMarkNotificationForSendingParams,
    Injections,
    StoreModel
  >;
}
export interface UpcomingNotificationRow extends TableRow {
  id?: TableRowId;
  patient_person_number: number;
  source_task_user_assigned: string;
  source_task_priority: number;
  source_task_type: string;
  source_task_description: string;
  source_task_id: string;
  patient_name: string;
  config_name: string;
  config_code: string;
  notification_for_config_triggered_at: string;
  notification_codename: string;
  should_send: boolean;
  notification_time: string;
  pcp_name: string;
  pcp_address: string;
}

interface HandleMarkNotificationForSendingParams {
  row: UpcomingNotificationRow;
  new_should_send: boolean;
}

export function getUpcomingNotificationsModel(): UpcomingNotificationsModel {
  return {
    ...tableModelFactory(
      "upcoming_notifications",
      "upcoming_notifications",
      (row) => row.notification_codename
    ),
    handleMarkNotificationForSending: thunk(
      async (
        actions: Actions<UpcomingNotificationsModel>,
        params: HandleMarkNotificationForSendingParams
      ) => {
        const { row, new_should_send } = params;
        const { source_task_id, config_code, patient_name } = row;

        const actionPayload: ActionPayload_MarkNotificationForSending = {
          task_id: source_task_id,
          config_code: config_code,
          should_send: new_should_send,
          upcoming_notification_data: row,
        };
        const notifDesc = `notification "${config_code}" for patient "${patient_name}"`;

        try {
          await submitUserAction__mark_notification_for_sending(actionPayload);
          toaster.success(`Successfully updated ${notifDesc}`, 2);
          await actions.handleFetchInitialData();
        } catch (e) {
          toaster.warning(`Failed to update ${notifDesc} -- please try again`);
        }
      }
    ),
  };
}
