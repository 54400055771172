import { Callout } from "@blueprintjs/core";
import { Routes } from "../../pages-routes";

const ClinicsPageCallout = () => (
  <Callout
    intent={null}
    title={null}
    css={`
      font-size: 17px;
    `}
  >
    <div>
      <p>
        On this page, you can curate the list of clinic codes acknowledged by
        Customax as "active". The active clinic codes are the values in the
        "Code" column below among rows with a blue checkmark in their "Active"
        column. Tasks in Intergy/PracticeAnalytics are associated with clinic
        codes via their "Task Type" field. Only tasks associated with an active
        clinic code can trigger notifications. In other words,{" "}
        <strong>
          each upcoming notification (i.e. row on the{" "}
          <a href={Routes.upcoming_notifications}>Upcoming Notifications</a>{" "}
          page) comes from a task whose "Task Type" value is an active clinic
          code.
        </strong>{" "}
      </p>
      <p>
        <strong>
          The only columns that affect Customax's functionality are Code and
          Active. The other columns (Clinic, State, and Region) are for
          documentation/filtering purposes only and do not affect Customax's
          functionality.
        </strong>
      </p>
      <p>
        To add a clinic, click the "Add Clinic" button (a circled green plus
        sign icon) in the blue ribbon above the table on this page and fill out
        and submit the form. To edit an existing clinic, click the "Actions"
        button (a blue gavel icon) to the right of the clinic row you want to
        edit and fill out and submit the form. To toggle whether an existing
        clinic is seen by Customax as active (i.e. to control whether tasks
        associated with that clinic can trigger notifications), click the
        checkbox in the "Active" column for the clinic row you want to toggle.{" "}
      </p>{" "}
      <p>
        Additions of new clinics and edits to existing clinics' "Code" or
        "Active" columns may take up to 20 minutes to affect which rows are
        shown on the{" "}
        <a href={Routes.upcoming_notifications}>Upcoming Notifications</a> page
        (and you will need to reload that page in your browser to see the
        changes).
      </p>
      <p>
        Please email <a href="mailto:austin@saxecap.com">austin@saxecap.com</a>{" "}
        if you have any questions.
      </p>
    </div>
  </Callout>
);

export default ClinicsPageCallout;
