import React, { useCallback, useState } from "react";
import { Spinner, Tag, Tooltip } from "@blueprintjs/core";
import styled from "styled-components/macro";
import {
  makeAuthorizedGetRequestToBackend3,
  makeUrl,
} from "../../helpers/backendApi";
import { toaster } from "../../toaster";
import { useStoreActions } from "../../hooks/ep";

const handleFetchSentNotificationContent = async ({
  notificationId,
  setSentNotificationContent,
}) => {
  try {
    const data = await makeAuthorizedGetRequestToBackend3({
      url: makeUrl(`sent_notifications/${notificationId}/content`),
    });
    setSentNotificationContent(data);
  } catch (e) {
    setSentNotificationContent(null);
    toaster.error("Something went wrong. Please reload and try again");
  }
};

export function SentNotificationViewerButtonCellRenderer({
  value,
}: {
  value: number;
}) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const setSentNotificationContent = useStoreActions(
    (a) => a.misc.setSentNotificationContent
  );

  const notificationId = value;
  const onClickFactory = useCallback(
    () => async () => {
      if (isLoading) {
        return;
      }
      setIsLoading(true);
      await handleFetchSentNotificationContent({
        notificationId: notificationId,
        setSentNotificationContent: setSentNotificationContent,
      });
      setIsLoading(false);
    },
    // eslint-disable-next-line
    [notificationId, handleFetchSentNotificationContent]
  );

  const onClick = onClickFactory();
  return (
    <span key={notificationId}>
      <Tooltip
        content={"View notification content"}
        boundary={"viewport"}
        hoverOpenDelay={200}
        hoverCloseDelay={0}
        isOpen={isLoading ? false : null}
      >
        <StyledTag
          fill={true}
          minimal={true}
          intent={isLoading ? "none" : "primary"}
          large={false}
          onClick={onClick}
          icon={
            isLoading ? (
              <Spinner size={Spinner.SIZE_SMALL} />
            ) : (
              "search-template"
            )
          }
          interactive={!isLoading}
          disabled={isLoading}
        />
      </Tooltip>
    </span>
  );
}

const StyledTag = styled(Tag)`
  font-size: 14px;
  height: 26px;
  & .bp3-icon,
  & .bp3-spinner {
    margin-right: 6px;
  }
`;
