import React, { useState } from "react";
import styled from "styled-components/macro";
import { Checkbox, Spinner } from "@blueprintjs/core";

export function CheckBoxCellRenderer({ colDef, data, crp }) {
  const onChange = crp.onChange;
  const [isLoading, setIsLoading] = useState(false);

  return (
    <StyledDiv>
      {isLoading ? (
        <Spinner size={Spinner.SIZE_SMALL} />
      ) : (
        <Checkbox
          onChange={async (e) => {
            setIsLoading(true);
            try {
              await onChange(
                crp.buildPayload(data, colDef.field, e.target.checked)
              );
            } catch (e) {}
            setIsLoading(false);
          }}
          checked={data[colDef.field]}
        />
      )}
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  & .bp3-spinner {
    margin-left: -16px;
    //margin-top: -5px;
  }
  & .bp3-checkbox {
    padding-left: 20px;
    padding-top: 10px;
  }
`;
