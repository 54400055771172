import { useCanCreateClinic } from "src/entities/clinics/authorization";
import CreateClinicButton from "src/components/clinics/create-clinic-button";

const useCustomRibbonLeftElements = () => {
  const canCreateClinic = useCanCreateClinic();
  return canCreateClinic
    ? [<CreateClinicButton key="button--create-clinic" />]
    : [];
};

export default useCustomRibbonLeftElements;
