import { Actions, Computed, computed, Thunk, thunk } from "easy-peasy";

import { parsePayloadToClinic } from "src/entities/clinics/parse-object-values";
import { Clinic, declareClinic } from "src/entities/clinics/user-actions";
import { StoreModel } from "src/store/model";
import { FieldName2Value } from "src/store/model-drawer-form";
import { Injections } from "src/store/store-injections";
import {
  TableModel,
  tableModelFactory,
  TableRow,
} from "src/store/table-model-factory";
import { toaster } from "src/toaster";

export interface ClinicsModel extends TableModel {
  rowData: Computed<ClinicsModel, TableRow[], StoreModel>;
  rowDataByUUID: Computed<
    ClinicsModel,
    { [key: string]: Clinic | TableRow },
    StoreModel
  >;
  handleDeclareClinic: Thunk<
    ClinicsModel,
    Clinic | FieldName2Value,
    Injections,
    StoreModel
  >;
  handleUpdateClinicActive: Thunk<
    ClinicsModel,
    [string, boolean],
    Injections,
    StoreModel
  >;
}

export const getClinicsModel = (): ClinicsModel => ({
  ...tableModelFactory("clinics", "clinics", (row) => row.uuid),
  rowData: computed([(state) => state.initialData], (rowData) => rowData),
  rowDataByUUID: computed([(state) => state.rowData], (rowData) =>
    Object.fromEntries(rowData.map((row) => [row.uuid, row]))
  ),
  handleDeclareClinic: thunk(
    async (
      actions: Actions<ClinicsModel>,
      payload: Clinic | FieldName2Value,
      { getState, dispatch }
    ) => {
      const state = getState();
      const existingCodes: string[] = state.initialData
        .filter((row) => row.uuid !== payload.uuid) // payload.uuid is undefined when creating a new clinic
        .map((row) => row.code.toUpperCase());
      if (existingCodes.includes(payload.code.toUpperCase())) {
        toaster.warning(
          `Code '${payload.code}' is already in use by an existing clinic. No two clinics can have the same code. If this error is unexpected, please reload the page and try again.`
        );
        return;
      }
      const declaredClinic: Clinic = parsePayloadToClinic(payload);
      try {
        await declareClinic(declaredClinic);
        toaster.success("Successfully updated clinics.", 2);
        await actions.handleFetchInitialData();
      } catch (e) {
        toaster.warning(
          `Failed to update clinics. Please reload the page and try again.`,
          2
        );
      }
    }
  ),
  handleUpdateClinicActive: thunk(
    async (
      actions: Actions<ClinicsModel>,
      [uuid, newActive]: [string, boolean],
      { getState, dispatch }
    ) => {
      const state = getState();
      const existingClinic = state.rowDataByUUID[uuid] as Clinic;
      const updatedClinic = {
        ...existingClinic,
        active: newActive,
      };
      await dispatch.clinics.handleDeclareClinic(updatedClinic);
    }
  ),
});
