import { Tooltip } from "antd";
import { TooltipPlacement } from "antd/es/tooltip";
import { Button, Icon, IconName, Intent } from "@blueprintjs/core";

import { parseFieldsToString } from "src/common/parse-object-values";
import DeclareClinicFormContent from "src/components/clinics/declare-clinic-form-content";
import { useCanCreateClinic } from "src/entities/clinics/authorization";
import { useStoreActions } from "src/hooks/ep";
import { FormType, useOpenDrawerForm } from "src/store/model-drawer-form";
import { AG_TABLE_RIBBON_BUTTON_TOOLTIP_DELAY } from "src/styles/constants";

const DEFAULT_INITIAL_CLINIC_DATA = {
  name: "",
  code: "",
  region: "",
  state: "",
  active: true,
};

const CreateClinicButton = () => {
  const openDrawer = useOpenDrawerForm();
  const handleSubmit = useStoreActions(
    (actions) => actions.clinics.handleDeclareClinic
  );
  const canCreateClinic = useCanCreateClinic();

  const onClick = () => {
    const title = "Please enter the clinic's details";
    const initialData = {
      fieldName2Value: parseFieldsToString(DEFAULT_INITIAL_CLINIC_DATA),
    };
    const ContentComponent = DeclareClinicFormContent;
    openDrawer({
      initialData,
      title,
      ContentComponent,
      handleSubmit,
      formType: FormType.DeclareClinic,
    });
  };

  return (
    canCreateClinic && (
      <Tooltip
        title="Add Clinic"
        placement={"topLeft" as TooltipPlacement}
        mouseEnterDelay={AG_TABLE_RIBBON_BUTTON_TOOLTIP_DELAY}
      >
        <Button
          css={`
            margin-left: 8px;
            margin-right: 8px;
          `}
          small
          minimal
          intent={Intent.SUCCESS}
          icon={<Icon icon={"add" as IconName} />}
          onClick={onClick}
        />
      </Tooltip>
    )
  );
};

export default CreateClinicButton;
