import { ActionCreator } from "easy-peasy";
import { useStoreActions, useStoreState } from "../../hooks/ep";
import _ from "lodash";
import { useMemo } from "react";
import { Select } from "@blueprintjs/select";
import { Button, MenuItem } from "@blueprintjs/core";
import { DEFAULT_CONFIG } from "../../store/model-config-selection";

export type Config = {
  code: string;
  name: string;
};

export function useConfigSelection(): [Config, ActionCreator<Config>] {
  let selectedConfig = useStoreState((s) => s.configSelection.selectedConfig);
  const selectConfig = useStoreActions((a) => a.configSelection.selectConfig);

  return [selectedConfig, selectConfig];
}

export function ConfigSelector(): JSX.Element {
  const [selectedConfig, selectConfig] = useConfigSelection();
  const allConfigs = useAllConfigs();

  return (
    <ConfigDropdown
      selectedConfig={selectedConfig}
      allConfigs={allConfigs}
      selectConfig={selectConfig}
    />
  );
}

export function useAllConfigs(): Config[] {
  let configsData = useStoreState((s) =>
    // Keep only `code` and `name` properties -- don't need `id` property for selection
    s.configurations.initialData.map(({ code, name }) => ({ code, name }))
  ) as Config[];

  return useMemo(() => {
    if (configsData.length > 1) {
      return [DEFAULT_CONFIG, ..._.sortBy(configsData, "code")];
    } else {
      return configsData;
    }
  }, [configsData]);
}

export function ConfigDropdown({
  selectedConfig,
  allConfigs,
  selectConfig,
}: {
  selectedConfig: Config;
  allConfigs: Config[];
  selectConfig: ActionCreator<Config>;
}): JSX.Element {
  return (
    <div
      css={`
        width: 100%;
      `}
    >
      <label className="bp3-label">
        Notification Configuration Name
        <Select
          large
          intent="primary"
          itemPredicate={itemPredicate}
          itemRenderer={(config, { handleClick, modifiers }) => {
            return (
              <MenuItem
                active={false}
                key={config.code}
                label={""}
                onClick={handleClick}
                text={config.code === "" ? "-ALL-" : config.name}
              />
            );
          }}
          items={allConfigs}
          onItemSelect={(config) => {
            selectConfig(config);
          }}
        >
          <Button
            large
            text={selectedConfig.code === "" ? "ALL" : selectedConfig.name}
            rightIcon="caret-down"
          />
        </Select>
      </label>
    </div>
  );
}

function itemPredicate(
  query: string,
  config: Config,
  index?: number,
  exactMatch?: boolean
) {
  if (exactMatch) {
    return config.name === query;
  } else if (!query.length) {
    return true;
  } else {
    return _.startsWith(config.name, query);
  }
}
