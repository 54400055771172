import { submitUserActionPostRequest, UserAction } from "./user_actions";
import { AxiosResponse } from "axios";
import { assert } from "../helpers/assertions";

export async function submitUserAction__mark_notification_for_sending(
  actionPayload: ActionPayload_MarkNotificationForSending
): Promise<AxiosResponse<UserAction_MarkNotificationForSending>> {
  verifyActionPayload(actionPayload);
  return await submitUserActionPostRequest(
    "mark_notification_for_sending",
    actionPayload
  );
}

function verifyActionPayload(
  actionPayload: ActionPayload_MarkNotificationForSending
): void {
  assert(typeof actionPayload.task_id === typeof "hi");
  assert(typeof actionPayload.config_code === typeof "hi");
  assert(typeof actionPayload.should_send === typeof true);
  assert(typeof actionPayload.upcoming_notification_data === typeof {});
}

export interface ActionPayload_MarkNotificationForSending {
  task_id: string;
  config_code: string;
  should_send: boolean;
  upcoming_notification_data: object;
}

export interface UserAction_MarkNotificationForSending
  extends UserAction<
    "mark_notification_for_sending",
    ActionPayload_MarkNotificationForSending
  > {}
