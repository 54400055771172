export default {
  apiKey: "AIzaSyBb4nKub-4brbHrHXz0dW5d9hG08Vw0BOo",
  authDomain: "sodium-cmax.firebaseapp.com",
  projectId: "sodium-cmax",
  storageBucket: "sodium-cmax.appspot.com",
  messagingSenderId: "426464210061",
  appId: "1:426464210061:web:4fbb45e07a3acdd5cfc6c4",
  measurementId: "G-GBGT3N5873",
  //
  databaseURL: "https://sodium-cmax.firebaseio.com",
};
