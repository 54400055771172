export function formatPhoneNumber(s) {
  if (!s || s === "null" || s === "None") {
    return "-";
  }
  const sz = s.length;
  const last4 = s.slice(sz - 4, sz);
  const first3 = s.slice(sz - 7, sz - 4);
  const areaCode = s.slice(sz - 10, sz - 7);
  const countryCode = sz > 10 ? s.slice(0, sz - 10) : "1";
  const f_countryCode = countryCode ? `+${countryCode} ` : "";
  const prefix = f_countryCode + (areaCode && `(${areaCode})`) + " ";
  return `${prefix}${first3}-${last4}`;
}
